import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header'; // Import the Header component
import "../sass/main.scss";
import "../sass/registergame.scss";
const RegisterGame = () => {
    const [cardNum, setCardNum] = useState("");
    const [lastCardNum, setLastCardNum] = useState("");
    const [round, setRound] = useState(0);
    const [balance, setBalance] = useState(0);
    const [sew, setSew] = useState(0);
    const [GameType, setGameType] = useState(1);
    const [birr, setBirr] = useState(0);
    const [auto, setAuto] = useState(1);
    const [profit, setProfit] = useState(0);
    const [totalCards, setTotalCards] = useState(0);
    const [selectedNum, setSelectedNum] = useState([]);
    const [amount, setAmount] = useState(10);
    const [pattern, setPattern] = useState(1);
    const [dataSent, setDataSent] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const cardsPerPage = 100; // Set to 50 cards per page
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    
    useEffect(() => {
        const fetchData = async () => {
            const userId = localStorage.getItem('userId'); // Retrieve user ID from local storage

            try {
                const response = await axios.get('https://hamsterbingo.com/api/registergame.php', {
                    params: { userId }, // Send userId as a query parameter
                });
                if (response.data.success) {
                    setRound(response.data.round);
                    setBalance(response.data.balance);
                    setSew(response.data.sew);
                    setBirr(response.data.birr);
                    setCardNum(response.data.cardnum);
                    setLastCardNum(response.data.lastcardnum);
                    setTotalCards(response.data.totalcard);
                    setAuto(response.data.auto);
                    setProfit(response.data.profit);
                    setGameType(response.data.GameType);
                    const cards = response.data.cardnum.split(',').map(Number);
                    cards.forEach(card => {
                        setTimeout(() => handleNumberClick(card), 0);
                    });
                } else {
                    console.error("Error in response:", response.data.message);
                }
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };

        fetchData();
    }, []);

   
  
    const handleNumberClick = (num) => {
   
        if(num>0){
            if (balance > 0) {
                setIsButtonDisabled(false);
                }
        setSelectedNum((prev) => {
            if (prev.includes(num)) {
                return prev.filter(n => n !== num);
            } else {
                return [...prev, num];
            }
        });
    }
    };
 
    const handlePlay = async () => {
        if (!dataSent && amount > 0) { // Check if data hasn't been sent yet
            const userId = localStorage.getItem('userId'); // Retrieve user ID from local storage
            var selectedcard=selectedNum.join(', ');
            try {
                const response = await axios.get('https://hamsterbingo.com/api/insertgame.php', {
                    params: { userId , selectedcard, amount, pattern },
                });
             
                setDataSent(true); // Mark data as sent
                    console.log('sucess');
                    startBingo(); 
            
            } catch (error) {
                console.error("Error during game insertion", error);
            }
        }
    };
    const startBingo = async () => {
        const userId = localStorage.getItem('userId'); // Retrieve user ID from local storage
        const n = selectedNum.length;
        const s = document.getElementById('amount').value;
        const b = balance; // Assuming balance is a state
        var btn=document.getElementById('mybtn');
        btn.disabled=true;

        let roundedTotal;
        if (auto) {
            if (n !== 0 && s !== 0 && b > 0) {
                btn.disabled = false;
            } else {
                btn.disabled = true;
            }

            // Calculate the total amount
            let total = n * s * 0.2;
            const dr = n * s; // Total sale

            // Determine discount based on total sale
            if (dr > 120 && dr < 320) {
                total = n * s * 0.2;
            } else if (dr >= 320 && dr < 800) {
                total = n * s * 0.22;
            } else if (dr >= 800 && dr < 1000) {
                total = n * s * 0.25;
            } else if (dr >= 1000) {
                total = n * s * 0.3;
            }

            // Get the last digit
            const lastDigit = total % 10;
            let roundedLastDigit = lastDigit > 5 ? 10 : 0;

            // Calculate new rounded total
            roundedTotal = total - lastDigit + roundedLastDigit;
            if (roundedTotal < 10 && total <= 10) {
                roundedTotal = 10;
            }
        } else {
            if (n !== 0 && s !== 0 && b > 0) {
                btn.disabled = false;
            } else {
                btn.disabled = true;
            }


            // Calculate total amount without discount
            roundedTotal = n * s * (profit / 100);
            if (roundedTotal < 10 && roundedTotal <= 10) {
                roundedTotal = 10;
            }
        }

    try {
            const response=await axios.get('https://hamsterbingo.com/api/insert_play_big.php', {
                params: { 
                sew: sew,
                birr: birr,
                income: roundedTotal,
                uid: userId,
            },
            });
           
            var total=n*s;
            localStorage.setItem('income', total-roundedTotal);
            localStorage.setItem('pattern', pattern);
            localStorage.setItem('GameType', GameType);
            window.location.href = '/bingo';
         
        } catch (error) {
            console.error("Error during AJAX request", error);
        }
            
    };



    const totalPages = Math.ceil(totalCards / cardsPerPage);
    const startIndex = (currentPage - 1) * cardsPerPage + 1;
    const endIndex = Math.min(startIndex + cardsPerPage - 1, totalCards);
    console.log(selectedNum);
    // Determine styles and message based on balance
    let backgroundColor;
    let message;

    if (balance <= 0) {
            backgroundColor = 'red'; // You can use a different color if you want
            message = 'Zero';
        
    } else if (balance >0 && balance <1000) {
        backgroundColor = 'red';
        message = 'Warning';
    }
    
    else if (balance >= 1000 && balance <= 4000) {
        backgroundColor = 'orange'; // You can use a different color if you want
        message = 'Moderate';
    }else {
        backgroundColor = 'green'; // Good balance
        message = 'Good';
    }

    return (
        
        <div className="register-game">
            
                <Header /> {/* Use the Header component */}
<div className="row-inputs">

    <div className="form-group">
        <label htmlFor="amount" className="form-label">Select Amount</label>
        <select 
            id="amount" 
            className="form-control" 
            value={amount} 
            onChange={(e) => setAmount(Number(e.target.value))}
        >
            <option value="">የብር መጠን</option>
            {[10, 20, 30, 40, 50, 100, 200, 300, 400, 500].map(value => (
                <option key={value} value={value}>{`በ ${value}ብር`}</option>
            ))}
        </select>
    </div>

    <div className="form-group">
        <label htmlFor="pattern" className="form-label">የጨወታ ትእዛዝ </label>
        <select 
            id="pattern" 
            className="form-control" 
            value={pattern} 
            onChange={(e) => setPattern(Number(e.target.value))}
        >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(value => (
                <option key={value} value={value}>
                    {(() => {
                        switch (value) {
                            case 1: return 'ኣንድ መስመር';
                            case 2: return 'ሁለት መስመር';
                            case 3: return 'ኣንድ መስመር ወደታች';
                            case 4: return 'ኣንድ መስመር ወደ ጎን';
                            case 5: return 'T';
                            case 6: return 'reverse T';
                            case 7: return 'X';
                            case 8: return 'L';
                            case 9: return 'reverse L';
                            case 10: return 'half above';
                            case 11: return 'half below';
                            case 12: return 'ሙሉ ዝግ';
                            default: return '';
                        }
                    })()}
                </option>
            ))}
        </select>
    </div>
    <div className="form-group">
<label htmlFor="amount" className="form-label">Round</label>
<div class="form-control"> Round {round}</div>
</div>
<div className="form-group">
<label htmlFor="amount" className="form-label">Wallet Status</label>
<div className="form-control" style={{ backgroundColor, color: 'white', padding: '10px', borderRadius: '5px' }}>
            {message}
        </div>
</div>
</div>

        
            <div className="register-game__content">
                <div className="register-game__balls">
                    <h2 style={{ color: 'white' }}>ካርድ ቁጥሮች</h2>
                    <div className="register-game__container" id="circledNumbers">
                        {Array.from({ length: endIndex - startIndex + 1 }, (_, index) => (
                            <div 
                                key={index} 
                                className={`register-game__box ${selectedNum.includes(startIndex + index) ? 'snum' : ''}`} 
                                onClick={() => handleNumberClick(startIndex + index)}
                            >
                                {startIndex + index}
                            </div>
                        ))}
                    </div>
                    <div className="pagination">
                        <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>Previous</button>
                        
                        {/* Number buttons */}
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button 
                                key={index} 
                                onClick={() => setCurrentPage(index + 1)} 
                                style={{ margin: '0 5px', backgroundColor: currentPage === index + 1 ? 'lightblue' : 'white',color: 'black' }}
                            >
                                {index + 1}
                            </button>
                        ))}

                        <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>Next</button>
                        <button 
                        id='mybtn'
                        onClick={handlePlay} 
                        style={{ width: '160px', height: '50px', borderRadius: '10px', backgroundColor: '#FF7F50'
                            ,color: '#333', marginLeft: '20%', fontSize: '32px' }}
                        disabled={isButtonDisabled}
                   >
                        PLAY
                    </button>
                    </div>
                </div>
                
                
            </div>
         
        </div>
    );
};

export default RegisterGame;
