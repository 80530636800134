import React, { useEffect, useState } from 'react';
import "../sass/mypattern.scss"; // Importing styles


const Mypattern = ({ pattern }) => {
   // alert(pattern);
    const allPatterns = {
        '1': [[1], [2], [3], [4], [5], [6], [7], [8], [9], [10], [13]],
        '2': [
            // Diagonal lines
            [1, 3], [2, 4], [3, 5], 
            [1, 5], [2, 4], [3, 3], [4, 2], [5, 1], 
            [1, 6], [1, 8], [1, 9], [1, 10],
            [2, 7], [2, 9], [2, 10],
            [3, 8], [3, 10],
            [4, 9], [5, 10],
            // Horizontal lines
            [1, 2], [1, 3], [1, 4], [1, 5],
            [2, 3], [2, 4], [2, 5],
            [3, 4], [3, 5],
            [4, 5],
            // Vertical lines
            [1, 6], [2, 7], [3, 8], [4, 9], [5, 10],
            [1, 7], [2, 8], [3, 9], [4, 10],
            [1, 8], [2, 9], [3, 10],
            [1, 9], [2, 10],
            [1, 10]
        ],
        '3': [[1], [2], [3], [4], [5]],
        '4': [[6], [7], [8], [9], [10]],
        '5': [[11]], 
        '6': [[12]], 
        '7': [[13]], 
        '8': [[14]], 
        '9': [[15]], 
        '10': [[16]], 
        '11': [[17]], 
        '12': [[18]]
    };

    const [patternIndex, setPatternIndex] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [bingoGrid, setBingoGrid] = useState(Array(5).fill().map(() => Array(5).fill(null)));

    const clearCircles = () => {
        setBingoGrid(Array(5).fill().map(() => Array(5).fill(null)));
    };

    const drawPattern = (patterns) => {
        const newGrid = Array(5).fill().map(() => Array(5).fill(null));
    
        patterns.forEach(pattern => {
            switch (pattern) {
                case 1: case 2: case 3: case 4: case 5:
                    let columnIndex = pattern - 1;
                    for (let i = 0; i < 5; i++) {
                        newGrid[i][columnIndex] = <div className="circle" key={`${i}-col`}></div>;
                    }
                    break;
    
                case 6: case 7: case 8: case 9: case 10:
                    let rowIndex = pattern - 6;
                    for (let i = 0; i < 5; i++) {
                        newGrid[rowIndex][i] = <div className="circle" key={`${rowIndex}-row-${i}`}></div>;
                    }
                    break;
    
                case 11: // T pattern
                    for (let i = 0; i < 5; i++) {
                        newGrid[0][i] = <div className="circle" key={`T-${i}`}></div>; // Middle row
                        newGrid[i][2] = <div className="circle" key={`T-v-${i}`}></div>; // Middle column
                    }
                    break;
    
                case 12: // Reverse T pattern
                    for (let i = 0; i < 5; i++) {
                        newGrid[4][i] = <div className="circle" key={`ReverseT-${i}`}></div>; // Bottom row
                        newGrid[i][2] = <div className="circle" key={`ReverseT-v-${i}`}></div>; // Middle column
                    }
                    break;
    
                case 13: // X pattern
                    for (let i = 0; i < 5; i++) {
                        newGrid[i][i] = <div className="circle" key={`X-${i}`}></div>; // Diagonal from top-left to bottom-right
                        newGrid[i][4 - i] = <div className="circle" key={`X-rev-${i}`}></div>; // Diagonal from top-right to bottom-left
                    }
                    break;
    
                case 14: // L pattern
                    for (let i = 0; i < 5; i++) {
                        newGrid[i][0] = <div className="circle" key={`L-v-${i}`}></div>; // Vertical line
                    }
                    for (let j = 0; j < 5; j++) {
                        newGrid[4][j] = <div className="circle" key={`L-h-${j}`}></div>; // Horizontal line at bottom
                    }
                    break;
    
                case 15: // Reverse L pattern
                    for (let i = 0; i < 5; i++) {
                        newGrid[i][4] = <div className="circle" key={`RevL-v-${i}`}></div>; // Vertical line
                    }
                    for (let j = 0; j < 5; j++) {
                        newGrid[4][j] = <div className="circle" key={`RevL-h-${j}`}></div>; // Horizontal line at bottom
                    }
                    break;
    
                case 16: // Half above pattern
                    for (let i = 0; i < 3; i++) {
                        for (let j = 0; j < 5; j++) {
                            newGrid[i][j] = <div className="circle" key={`HalfAbove-${i}-${j}`}></div>;
                        }
                    }
                    break;
    
                case 17: // Half below pattern
                    for (let i = 2; i < 5; i++) {
                        for (let j = 0; j < 5; j++) {
                            newGrid[i][j] = <div className="circle" key={`HalfBelow-${i}-${j}`}></div>;
                        }
                    }
                    break;
    
                case 18: // Full pattern
                    for (let i = 0; i < 5; i++) {
                        for (let j = 0; j < 5; j++) {
                            newGrid[i][j] = <div className="circle" key={`Full-${i}-${j}`}></div>;
                        }
                    }
                    break;
    
                default:
                    console.error('Pattern not recognized:', pattern);
                    break;
            }
        });
    
        setBingoGrid(newGrid);
    };
    

    const updatePattern = () => {
        clearCircles();
        drawPattern(allPatterns[pattern][patternIndex]);

        if (allPatterns[pattern].length > 1) {
            const id = setInterval(() => {
                setPatternIndex(prevIndex => {
                    const nextIndex = (prevIndex + 1) % allPatterns[pattern].length;
                    drawPattern(allPatterns[pattern][nextIndex]);
                    return nextIndex;
                });
            }, 1500);
            setIntervalId(id);
        }
    };

    useEffect(() => {
        setPatternIndex(0); // Reset index when pattern changes
        clearCircles();
        updatePattern();
        console.log('Current pattern:', pattern); // Log the current pattern
        return () => clearInterval(intervalId);
    }, [pattern]);

    return (
        <div>
            <table id="bingoTable">
                <thead>
                    <tr>
                        <th>B</th>
                        <th>I</th>
                        <th>N</th>
                        <th>G</th>
                        <th>O</th>
                    </tr>
                </thead>
                <tbody>
                    {bingoGrid.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex}>{cell}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Mypattern;
