// src/components/Header.js
import React from 'react';
import { Link } from "react-router-dom";
import logo from "../images/hamster-192.ico"; // Update the path as needed

const Header = () => {
    return (
        <header>
            <div className="container row align-center">
                <div className="col shrink">
                    <Link to="/">
                        <img src={logo} alt="Let's Play Bingo!" className="logo" />
                    </Link>
                </div>
                <div className="col grow padding-md no-text-wrap text-right">
                <ul className="menu" >

                        <li ><Link to="/" style={{  color: '#f0f0f0' }}>Report</Link></li>
                        <li ><Link to="/" style={{  color: '#f0f0f0' }}>Logout</Link></li>
                    </ul>
                </div>
            
            </div>
        </header>
    );
};

export default Header;
