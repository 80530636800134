import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// Styles
//import "./sass/main.scss";
// Images
//import logo from "./images/Logo with color.png";
import BingoGame from "./components/BingoGame.js";
import Login from "./components/login.js";
import RegisterGame from "./components/registergame.js";
import BingoTable from "./components/bingotable.js";
import CardGenerator from "./components/pages/CardGenerator.js";

import Patterns from "./components/pages/Patterns.js";

const App = () => {
    return (
        <Router>


            <Routes>
            <Route path="/" element={<Login />} /> {/* Set Login as root */}
            <Route path="/bingo" element={<BingoGame />} /> {/* BingoGame route */}
            <Route path="/registergame" element={<RegisterGame />} />
            <Route path="/bingotable" element={<BingoTable />} />
                <Route path="/generator" element={<CardGenerator />} />
                <Route path="/patterns" element={<Patterns />} />
               
            </Routes>

         
        </Router>
    );
};

ReactDOM.render(<App />, document.getElementById("root"));
