import React, { Component } from 'react';
import { openDB } from 'idb';

class VoiceSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCaller: "1", // Default value
            speechEnabled: true,
            enableCaller: true,
        };
        this.dbPromise = this.initDB(); // Initialize the DB on component creation
    }

    async initDB() {
        return openDB('audio-cache-db', 1, {
            upgrade(db) {
                db.createObjectStore('audios');
            }
        });
    }

    async storeAudio(url, blob) {
        const db = await this.dbPromise;
        const tx = db.transaction('audios', 'readwrite');
        tx.store.put(blob, url);
        await tx.done;
    }

    async getAudio(url) {
        const db = await this.dbPromise;
        return db.get('audios', url);
    }

    async playAudio(voicePath) {
        const audioUrl = voicePath;
        let blob = await this.getAudio(audioUrl);

        if (!blob) {
            try {
                const response = await fetch(audioUrl);
                if (!response.ok) throw new Error('Network response was not ok');
                blob = await response.blob();
                await this.storeAudio(audioUrl, blob);
            } catch (error) {
                console.error('Failed to fetch audio file:', error);
                return;
            }
        }

        const audio = new Audio(URL.createObjectURL(blob));
        audio.preload = 'auto';
        audio.play().catch(error => console.error('Error playing audio:', error));
    }

    voices = (rb) => {
        const selectvoice = document.getElementById("voiceselect").value; // Get the selected voice
       
        const voiceFiles = [
            '1.wav', '2.wav', '3.wav', '4.wav', '5.wav', '6.wav', '7.wav', '8.wav', '9.wav', '10.wav', 
            '11.wav', '12.wav', '13.wav', '14.wav', '15.wav', '16.wav', '17.wav', '18.wav', '19.wav', 
            '20.wav', '21.wav', '22.wav', '23.wav', '24.wav', '25.wav', '26.wav', '27.wav', '28.wav', 
            '29.wav', '30.wav', '31.wav', '32.wav', '33.wav', '34.wav', '35.wav', '36.wav', '37.wav', 
            '38.wav', '39.wav', '40.wav', '41.wav', '42.wav', '43.wav', '44.wav', '45.wav', '46.wav', 
            '47.wav', '48.wav', '49.wav', '50.wav', '51.wav', '52.wav', '53.wav', '54.wav', '55.wav', 
            '56.wav', '57.wav', '58.wav', '59.wav', '60.wav', '61.wav', '62.wav', '63.wav', '64.wav', 
            '65.wav', '66.wav', '67.wav', '68.wav', '69.wav', '70.wav', '71.wav', '72.wav', '73.wav', 
            '74.wav', '75.wav'
        ];
        const directories = [
           'voice/','nigus/', 'modern arada/','modern formal/', 'fana/', 'famharic/', 'foromifa/', 'gual/', 'wedi/'
        ];

        const path = directories[selectvoice - 1];
        const voiceFile = voiceFiles[rb - 1];

        if (path && voiceFile) {
            this.playAudio(path + voiceFile);
        } else {
            console.error('Invalid voice selection or file index');
        }
    };

    handleChooseCaller = (event) => {
        const selectvoice = event.target.value;
        this.setState({ selectedCaller: selectvoice });
        this.voices(selectvoice);
    };
    componentDidUpdate(prevProps) {
        // Check if the prop has changed and play audio accordingly
        if (this.props.numberToCall !== prevProps.numberToCall) {
            this.voices(this.props.numberToCall);
        }
    }
    render() {
        return (
            <div
                className="row align-start justify-start"
                data-visibility={this.state.speechEnabled && this.state.enableCaller ? "show" : "hide"}
            >
                <div className="col grow min-size-70 padding-horizontal-md">
                    <select
                        id="voiceselect"
                        style={{ height: '40px', width: '80%' }}
                        value={this.state.selectedCaller}
                        onChange={this.handleChooseCaller}
                    >
                        <option value="1">bereket</option>
                        <option value="2">Nigus</option>
                        <option value="3">arada</option>
                        <option value="4">Arada formal</option>
                        <option value="5">Amharic yordi</option>
                        <option value="6">Amharic Beti</option>
                        <option value="7">Oromifa Female</option>
                        <option value="8">Tigrigna Female</option>
                        <option value="9">Tigrigna male</option>
                    </select>
                </div>
            </div>
        );
    }
}

export default VoiceSelector;
